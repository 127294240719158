import { CustomNavLink } from '../../routes';
import { ReactComponent as AccuratorLogo } from '../../../assets/images/logo-light.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/svg/project.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg';
import { ReactComponent as StartIcon } from '../../../assets/svg/start.svg';
import { ReactComponent as InternIcon } from '../../../assets/svg/intern.svg';
import { ReactComponent as ActivityIcon } from '../../../assets/svg/activities.svg';
import { ReactComponent as SupplierIcon } from '../../../assets/svg/suppliers.svg';
import { ReactComponent as OrganizationIcon } from '../../../assets/svg/organization.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/svg/reports.svg';
import { ReactComponent as VideosIcon } from '../../../assets/svg/videos.svg';
import { ReactComponent as MarketingIcon } from '../../../assets/svg/marketingIcon.svg';
import { ReactComponent as NewsIcon } from '../../../assets/svg/news.svg';
import { ReactComponent as BusinessCardIcon } from '../../../assets/svg/businessCard.svg';
import { ReactComponent as FlyerTakenIcon } from '../../../assets/svg/flyerTakenIcon.svg';
import { ReactComponent as MonitorIcon } from '../../../assets/svg/monitor-icon.svg';
import { ReactComponent as FilesIcon } from '../../../assets/svg/files.svg';

import RequestQuoteIcon from '../../../components/svgs/requestQuoteIcon';

import appState from '../../../state/AppStateContainer';

import './leftMenuBar.scss';
import NotificationCounter from '../NotificationCounter/NotificationCounter';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ServiceChat } from '../../../services/service-chat';
import _ from 'lodash';
import Badge from '../Badge/Badge';
import GlobalSearch from './GlobalSearch';
type MenuItem = {
  title: string,
  to: string,
  icon: ReactNode,
  product?: string,
  notifications?: number,
  counter?: number,
  rightIcon?: ReactNode,
};

export default function leftMenuBar() {
  const superAdminCheck = appState.isSuperAdmin() && appState.getCurrentOrgSlug() === 'superadmin';
  const { project, internalChat } = appState.getGlobalNotifications();
  const [searchOpen, setSearchOpen] = useState(false);
  //const [searchValue, setSearchValue] = useState("");
  //const [searchResults, setSearchResults] = useState([]);
  const showSearch = useCallback((e) => {
    setSearchOpen(true);
    e.preventDefault();
  }, []);

  const menuItems: MenuItem[] = [
    {
      title: 'Start',
      to: '/dashboard',
      icon: <StartIcon />,
      product: "PURCHASING",
    },
    {
      title: 'Projekt',
      to: '/projects',
      icon: <ProjectIcon />,
      product: "PURCHASING",
      notifications: project,
    },
    {
      title: 'Leverantörer',
      to: '/supplier-database',
      icon: <SupplierIcon />,
      counter: appState.isAdminRole() ? appState.getSupplierApplicationCount() : 0,
      product: "PURCHASING",
    },
    {
      title: 'Leverantörsbevakning',
      to: '/supplier-monitoring',
      product: 'PURCHASING',
      icon: <MonitorIcon className='leftMenuBarMonitorIcon' />,
    },
    {
      title: 'Filer',
      to: '/files',
      product: 'PURCHASING',
      icon: <FilesIcon />,
    },
    {
      title: 'Aktiviteter',
      to: '/tasks',
      icon: <ActivityIcon />,
      product: "PURCHASING",
    },
    {
      title: 'Mottagna förfrågningar',
      to: '/requests',
      icon: <RequestQuoteIcon color={undefined} />,
      product: "SALES",
    },
    {
      title: 'Publicerade förfrågningar',
      to: '/published-requests',
      icon: <FlyerTakenIcon color={undefined} />,
      product: "SALES",
    },
    {
      title: 'Marknadsföring',
      to: '/marketing',
      icon: <MarketingIcon color={undefined} className='menu-list-marketing-icon' />,
      product: "SALES",
    },
    {
      title: 'Presentation',
      to: '/supplier-presentation',
      icon: <BusinessCardIcon color={undefined} />,
      rightIcon: null,
      product: "SALES",
    },
    {
      title: 'Användare',
      to: '/chat',
      icon: <InternIcon />,
      notifications: internalChat,
    },
    {
      title: 'Organisationer',
      to: '/organizations',
      icon: <OrganizationIcon />,
    },
    {
      title: 'Rapporter',
      to: '/reports',   
      icon: <ReportsIcon />,   
    },
    {
      title: 'Fakturor',
      to: '/invoices',
      icon: <ReportsIcon />,
    },
    {
      title: 'Instruktionsfilmer',
      to: '/help',
      icon: <VideosIcon />,
      product: "PURCHASING",
    },
    {
      title: 'Uppdateringar',
      to: '/updates',
      icon: <NewsIcon />, 
    },
  ];

  if (!appState.hasSupplierProfileBeenUpdated()) {
    const index = menuItems.findIndex(item => item.to === '/supplier-presentation');
    if (index !== -1) {
      menuItems[index].rightIcon = <Badge style={{ marginLeft: '8px', backgroundColor: 'var(--gray-600)', color: 'var(--white)' }} backgroundColor='var(--gray-50)'>saknas</Badge>;
    }
  }

  useEffect(() => {
    (async () => {      
      await appState.refreshProjectNotifications();
    })();
    (async () => {
      const notifications = await ServiceChat.getNotifications();
      const chatNotification = notifications.reduce((a, b) => a + b.count, 0);
      appState.setGlobalNotifications({ internalChat: chatNotification });
    })();
  }, []);

  const isActiveRoute = (link: string) => location.pathname.includes(link);
  const os = (navigator.platform.match(/mac|win|linux/i) || ["other"])[0].toLowerCase();
  const shortcut = os == "mac" ? "⌘+K" : "Ctrl+K";
  const organizationHasSyna = appState.getOrgFromUrl()?.hasSyna;

  const logoUrl = appState.getLogoUrl();

  return (
    <div className="app-menu-left conv--wrapper">
      <div style={{ backdropFilter: 'blur(10px)', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', }}>
        <CustomNavLink to="/dashboard">
          <div className="logo-wrapper">
            {logoUrl ? <img src={logoUrl} style={{ maxWidth: '200px' }} /> : <AccuratorLogo width={40} height={50} />}
          </div>
        </CustomNavLink>

        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <div className='menu-list'>
            {(appState.getActiveProducts().includes("PURCHASING") || appState.isSuperAdmin()) && <>
              <a href="" onClick={showSearch} title={`Sök bland projekt, leverantörer och sidor och gå direkt dit. ${shortcut}`}>
                <SearchIcon />
                Sök
              </a>
              <GlobalSearch show={searchOpen} setShow={setSearchOpen} />
            </>}
            {menuItems?.map(menu => {
              if (superAdminCheck && (menu.to === '/projects' || menu.to === '/tasks' || menu.to === '/requests' || menu.to === '/marketing' || menu.to === '/supplier-presentation' || menu.to === '/published-requests' || menu.to === '/supplier-monitoring' || menu.to === '/files')) return null;
              if (appState.isClientRole() && (menu.to === '/supplier-database' || menu.to === '/tasks')) return null;
              if (!superAdminCheck && (menu.to === '/organizations' || menu.to === '/reports' || menu.to === '/invoices')) return null;
              if (!superAdminCheck && menu.product && !(appState.getActiveProducts().includes(menu.product))) return null;
              if (menu.to === '/supplier-monitoring' && !organizationHasSyna) return null;
              return (
                <CustomNavLink to={menu.to} key={menu.to} className={isActiveRoute(menu.to) ? 'active' : ''}>
                  {menu.icon}
                  {menu.title}
                  {menu.counter ? (<NotificationCounter counter={menu.counter} style={{marginLeft: "8px", backgroundColor: "var(--gray-600)"}} />) : (null)}
                  {menu.notifications ? (<NotificationCounter counter={menu.notifications} style={{ marginLeft: "8px", }} />) : null}
                  {menu.rightIcon ?? null}
                </CustomNavLink>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
